import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout/layout";
import { moduleMap } from "../../utils/moduleMap";
import Seo from "../../components/seo/seo";

export const query = graphql`
  query($id: String!) {
    contentfulPage(id: { eq: $id }) {
      id
      title
      seoMetaDescription
      slug
      theme
      node_locale
      updatedAt
      createdAt
      modules {
        __typename
        ... on Node {
          ...Modules
        }
      }
      createdAt
      updatedAt
    }
  }
`;

const Page = ({ data, pageContext }) => {
  const page = data.contentfulPage;

  // Map modules for placement in the page's primary content area
  const modules = page.modules.map((el, i) => {
    let Module = moduleMap[el.__typename];
    return <Module moduleId={`module-${i + 1}`} key={i} {...el} />;
  });

  return (
    <Layout theme={page.theme} type={pageContext.type}>
      {modules}
    </Layout>
  );
};

export default Page;

export const Head = ({
  data: {
    contentfulPage: { title, seoMetaDescription, createdAt, updatedAt },
  },
  pageContext: { slug, locale },
}) => {
  const seoMeta = {
    metaTitle: title,
    metaDescription: seoMetaDescription,
    slug,
    locale,
    createdAt,
    updatedAt,
  };
  return <Seo {...seoMeta} />;
};
